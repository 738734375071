import { Component, Input, ViewChild } from '@angular/core';
import { MatExpansionPanel } from '@angular/material/expansion';

@Component({
  selector: 'app-expansion-panel',
  templateUrl: './expansion-panel.component.html',
  styleUrls: ['./expansion-panel.component.scss']
})
export class ExpansionPanelComponent {
  @Input() disabled: boolean = false;
  @Input() itemId?: number;

  @ViewChild(MatExpansionPanel) expansionPanel!: MatExpansionPanel;

  open(): void {
    this.expansionPanel?.open();
  }

  close(): void {
    this.expansionPanel?.close();
  }
}