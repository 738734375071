import { AbstractControl, FormArray, ValidatorFn } from "@angular/forms";
import { TicketFormPricesType } from "./product-ticket.helpers";

export enum PriceFormArrayErrorKeys {
  DUPLICATE_CURRENCY_CODE = 'duplicateCurrencyCode',
  NO_PRICES = 'noPrices',
}

export interface PriceFormArrayErrors {
  [PriceFormArrayErrorKeys.NO_PRICES]?: boolean;
  [PriceFormArrayErrorKeys.DUPLICATE_CURRENCY_CODE]?: boolean;
}

export function pricesValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    if (!(control instanceof FormArray)) {
      return null; // Nie jest FormArray
    }

    const pricesArray = control as TicketFormPricesType;
    
    // 1. Sprawdź, czy jest przynajmniej jeden element
    if (pricesArray.length === 0) {
      return { noPrices: true };
    }

    // 2. Sprawdź unikalność currencyCode
    const allCurrencyCodes = pricesArray.controls.map((group) => group.controls.currencyCode.value);
    const filteredCurrencyCodes = allCurrencyCodes.filter(c => !!c);
    const hasDuplicates = filteredCurrencyCodes
      .some((code, index) => filteredCurrencyCodes.indexOf(code) !== index);

    if (hasDuplicates) {
      return { duplicateCurrencyCode: true };
    }

    return null; // Wszystko jest w porządku
  };
}