import { FormArray, FormControl, FormGroup, Validators } from "@angular/forms";
import { EventLanguageModel } from "@store/features/event/models/event-language.model";
import { TagApiModel } from "@store/features/tags/tags.models";
import { ModelForm } from "@utils/model-form.util";
import { pricesValidator } from "./product-price.validators";
import { ProductBundleCreateModel, ProductBundleItemAttributeCreateModel, ProductBundleItemAttributeUpdateModel, ProductBundleItemPriceAttributeCreateModel, ProductBundleItemPriceAttributeUpdateModel, ProductBundleModel, ProductBundleUpdateModel } from "@store/features/product/models/product-bundle.model";
import { TicketCreateModel, TicketModel, TicketTranslatableFields } from "@store/features/product/models/ticket.model";
import { ProductBundleItemType } from "@store/features/product/utils/product-bundle-item-type";
import { CurrencyModel } from "@store/features/product/models/currency.model";
import { ProductOfferGroupModel } from "./product-offers.helpers";
import { EventModel } from "@store/features/event/models/event.model";
import { DateTime } from "luxon";
import { AddonModel } from "@store/features/product/models/addon.model";
import { ProductVisibility } from "@store/features/product/utils/product-visibility-type";
import { linkVisibilityTokenValidator, visibilityTypeValidator } from "./product-visibility.validators";

export type TicketFormTranslationsType = FormArray<FormGroup<ModelForm<TicketTranslationsFormModel>>>;
export type TicketFormPricesType = FormArray<FormGroup<ModelForm<TicketPriceFormModel>>>;
export type TicketFormBundlesType = FormArray<FormGroup<ModelForm<ProductBundleFormModel>>>;
export type TicketFormAddonsType = FormArray<FormGroup<ModelForm<TicketAddonFormModel>>>;
export type TicketFormSessionsType = FormArray<FormGroup<ModelForm<TicketSessionFormModel>>>;
export type ProductLocalFormType = FormGroup<ModelForm<ProductFormModel>>;

export interface TicketTranslationsFormModel {
  locale: string;
  name: string;
  description: string;
}

export interface TicketFormModel {
  id: number | null;
  customFormId: number | null;
  assignedGroup: TagApiModel | null;
  translations: TicketFormTranslationsType;
}

// form data value object (part of this.form.value)
export interface TicketFormValueModel {
  id: number | null;
  customFormId: number | null;
  assignedGroup: TagApiModel | null;
  translations: TicketTranslationsFormModel[];
}

export interface TicketPriceFormModel {
  price: number; // brutto price
  currencyCode: string;
  vat: number;
}

export interface ProductBundleFormModel {
  id: number; // it should be an real ID or temp ID (temp === timestamp)
  name: string | null; // not editable
  availableFrom: Date | null;
  availableTo: Date | null;
  visibilityType: ProductVisibility;
  linkVisibilityToken: string | null;
  userGroups: TagApiModel[] | null; // null if ProductDisplayMode.Link
  prices: TicketFormPricesType;
  quantity: number | null;
  addons: TicketFormAddonsType;
  sessionTickets: TicketFormSessionsType;
}

// form data value object (part of this.form.value)
export interface ProductBundleFormValueModel {
  id: number; // it should be an real ID or temp ID (temp === timestamp)
  name: string | null; // not editable
  availableFrom: Date | null;
  availableTo: Date | null;
  visibilityType: ProductVisibility;
  linkVisibilityToken: string | null;
  userGroups: TagApiModel[] | null; // null if ProductDisplayMode.Link
  prices: TicketPriceFormModel[];
  quantity: number | null;
  addons: TicektAddonFormValueModel[];
  sessionTickets: TicektSessionFormValueModel[];
}

export interface ProductFormModel {
  mainProduct: FormGroup<ModelForm<TicketFormModel>>;
  bundles: TicketFormBundlesType;
}

// form data value object (MAIN part of this.form.value)
export interface ProductFormValueModel {
  mainProduct: TicketFormValueModel;
  bundles: ProductBundleFormValueModel[];
}

export interface ProductSaveParams {
  event: EventModel;
  mainProduct: TicketModel;
  mainProductType: ProductBundleItemType;
  form: ProductFormValueModel;
  eventCurrencies: CurrencyModel[];
  bundles: ProductBundleModel[];
}

export interface TicketAddonFormModel {
  addonId: number;
  quantity: number;
  prices: TicketFormPricesType;
}

export interface TicektAddonFormValueModel {
  addonId: number;
  quantity: number;
  prices: TicketPriceFormModel[];
}

export interface TicketSessionFormModel {
  sessionTicketId: number;
  quantity: number;
  prices: TicketFormPricesType;
}

export interface TicektSessionFormValueModel {
  sessionTicketId: number;
  quantity: number;
  prices: TicketPriceFormModel[];
}

type ItemPricePayloadType = ProductBundleItemPriceAttributeUpdateModel | ProductBundleItemPriceAttributeCreateModel;

export const TICKET_NAME_MAX_LENGTH = 128;
export const TICKET_DESC_MAX_LENGTH = 256;

export const formatPrice = (value: number, precision: number): string => {
  const formattedPrice = (value / Math.pow(10, precision)).toFixed(precision);
  return formattedPrice;
}

export const formatPriceForAPI = (value: number, precision: number): number => {
  const apiPrice = Math.round(value * Math.pow(10, precision));
  return apiPrice;
}

export const generateLinkTokenRandomString = (): string => {
  const chars = 'abcdefghijklmnopqrstuvwxyz0123456789';
  let result = '';
  for (let i = 0; i < 16; i++) {
    result += chars.charAt(Math.floor(Math.random() * chars.length));
  }
  return result;
}

const createTicketTranslationFormGroup = (localeCode: string): FormGroup<ModelForm<TicketTranslationsFormModel>> => {
  return new FormGroup<ModelForm<TicketTranslationsFormModel>>({
    locale: new FormControl(localeCode),
    name: new FormControl(null, [Validators.required, Validators.maxLength(TICKET_NAME_MAX_LENGTH)]),
    description: new FormControl(null, [Validators.maxLength(TICKET_DESC_MAX_LENGTH)]),
  });
}

const createTicketFormGroup = (languages: EventLanguageModel[]): FormGroup<ModelForm<TicketFormModel>> => {
  return new FormGroup<ModelForm<TicketFormModel>>({
    id: new FormControl(-1),
    customFormId: new FormControl(null),
    assignedGroup: new FormControl(null),
    translations: new FormArray([...languages.map(l => createTicketTranslationFormGroup(l.code))]),
  });
}

const createPriceFormGroup = (currencyCode: string): FormGroup<ModelForm<TicketPriceFormModel>> => {
  return new FormGroup<ModelForm<TicketPriceFormModel>>({
    price: new FormControl(0, [Validators.required, Validators.min(0)]),
    currencyCode: new FormControl(currencyCode, Validators.required),
    vat: new FormControl(0, [Validators.min(0), Validators.max(100)])
  });
}

const createProductBundleForm = (): FormGroup<ModelForm<ProductBundleFormModel>> => {
  return new FormGroup<ModelForm<ProductBundleFormModel>>({
    id: new FormControl(-Date.now()), // new bundles have negative id number
    name: new FormControl(null),
    availableFrom: new FormControl(null),
    availableTo: new FormControl(null),
    visibilityType: new FormControl(ProductVisibility.NO_RESTRICITION),
    linkVisibilityToken: new FormControl(null),
    userGroups: new FormControl(null),
    prices: new FormArray([], pricesValidator()),
    quantity: new FormControl(null),
    addons: new FormArray([]),
    sessionTickets: new FormArray([]),
  }, {
    validators: [linkVisibilityTokenValidator(), visibilityTypeValidator()]
  });
}

const createTicketAddonFormGroup = (addonId: number): FormGroup<ModelForm<TicketAddonFormModel>> => {
  return new FormGroup<ModelForm<TicketAddonFormModel>>({
    addonId: new FormControl(addonId),
    quantity: new FormControl(null),
    prices: new FormArray([], pricesValidator()),
  });
}

const createTicketSessionFormGroup = (sessionTicketId: number): FormGroup<ModelForm<TicketSessionFormModel>> => {
  return new FormGroup<ModelForm<TicketSessionFormModel>>({
    sessionTicketId: new FormControl(sessionTicketId),
    quantity: new FormControl(null),
    prices: new FormArray([], pricesValidator()),
  });
}

const createProductForm = (languages: EventLanguageModel[]): ProductLocalFormType => {
  return new FormGroup<ModelForm<ProductFormModel>>({
    mainProduct: createTicketFormGroup(languages),
    bundles: new FormArray([]),
  });
}

const eventDefaultLanguage = (languages: EventLanguageModel[]): EventLanguageModel => {
  const lang = languages.find(l => l.default);
  return lang || languages[0];
}

const getBundleLabelFromForm = (bundle: ProductBundleFormModel): string => {
  const formatDate = (date: Date | null): string => {
    if (!date) return '';
    
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    
    return `${day}/${month}/${year} ${hours}:${minutes}`;
  };

  const availableFrom = bundle.availableFrom ? formatDate(new Date(bundle.availableFrom)) : 'now';
  const availableTo = bundle.availableTo ? formatDate(new Date(bundle.availableTo)) : 'end';

  return `${availableFrom}  →  ${availableTo}`;
}

const getBundlesToCreateFrom = (params: ProductSaveParams): ProductBundleCreateModel[] => {
  const { event, mainProduct, mainProductType, form, eventCurrencies } = params;
  const bundles = form.bundles;
  const bundlesToCreate = bundles.filter(b => b.id < 0);
  const objectsToSave: ProductBundleCreateModel[] = [];

  bundlesToCreate.forEach((bundleForm, index) => {
    const payload: ProductBundleCreateModel = {
      name: bundleForm.name || createBundleName(index),
      isPublished: false,
      exclusions: {},
      inSaleFrom: bundleForm.availableFrom ? DateTime.fromJSDate(bundleForm.availableFrom, { zone: event.timezone }).toString() : null,
      inSaleTo: bundleForm.availableTo ? DateTime.fromJSDate(bundleForm.availableTo, { zone: event.timezone }).toString() : null,
      visibilityType: bundleForm.visibilityType,
      linkVisibilityToken: bundleForm.linkVisibilityToken,
      userGroupIds: bundleForm.userGroups?.map(g => g.id) || null,
      bundleItemsAttributes: [{
        itemableType: mainProductType,
        itemableId: mainProduct.id,
        availableQuantity: bundleForm.quantity || null,
        bundleItemPricesAttributes: getBundlePricesToCreateFrom(bundleForm.prices, eventCurrencies),
        discountBundleItemsAttributes: [],
      },
        ...getAddonsToSaveFrom<ProductBundleItemAttributeCreateModel>(bundleForm, eventCurrencies),
        ...getSessionsToSaveFrom<ProductBundleItemAttributeCreateModel>(bundleForm, eventCurrencies),
      ],
    };
    objectsToSave.push(payload);
  });
  return objectsToSave;
}

const getAddonsToSaveFrom = <T>(bundleForm: ProductBundleFormValueModel, eventCurrencies: CurrencyModel[], bundle?: ProductBundleModel): T[] => {
  const addons = bundleForm.addons;
  const items = [];
  addons.forEach(addon => {
    const payload = {
      itemableType: ProductBundleItemType.EVENT_ADDON,
      itemableId: addon.addonId,
      availableQuantity: addon.quantity,
      bundleItemPricesAttributes: getBundlePricesToCreateFrom(addon.prices, eventCurrencies),
      discountBundleItemsAttributes: [],
      ...(bundle ? { bundleItemId: getBundleAttributeIdFromExistingBundle(addon.addonId, ProductBundleItemType.EVENT_ADDON, bundle) } : {}),
    } as unknown as T;
    items.push(payload);
  });
  return items;
}

const getSessionsToSaveFrom = <T>(bundleForm: ProductBundleFormValueModel, eventCurrencies: CurrencyModel[], bundle?: ProductBundleModel): T[] => {
  const sessions = bundleForm.sessionTickets;
  const items = [];
  sessions.forEach(session => {
    const payload = {
      itemableType: ProductBundleItemType.AGENDA_SESSION,
      itemableId: session.sessionTicketId,
      availableQuantity: session.quantity,
      bundleItemPricesAttributes: getBundlePricesToCreateFrom(session.prices, eventCurrencies),
      discountBundleItemsAttributes: [],
      ...(bundle ? { bundleItemId: getBundleAttributeIdFromExistingBundle(session.sessionTicketId, ProductBundleItemType.AGENDA_SESSION, bundle) } : {}),
    } as unknown as T;
    items.push(payload);
  });
  return items;
}

const getBundlesToUpdateFrom = (params: ProductSaveParams): [number, ProductBundleUpdateModel][] => {
  const { event, mainProduct, mainProductType, form, eventCurrencies } = params;
  const bundles = form.bundles;
  const bundlesToUpdate = bundles.filter(b => b.id >= 0);
  const objectsToSave: [number, ProductBundleUpdateModel][] = [];

  bundlesToUpdate.forEach((bundleForm, index) => {
    const bundle = params.bundles.find(b => b.id === bundleForm.id) || null;
    if (!bundle) {
      return;
    }
    const payload: ProductBundleUpdateModel = {
      name: bundle.name,
      isPublished: false,
      exclusions: {},
      inSaleFrom: bundleForm.availableFrom ? DateTime.fromJSDate(bundleForm.availableFrom, { zone: event.timezone }).toString() : null,
      inSaleTo: bundleForm.availableTo ? DateTime.fromJSDate(bundleForm.availableTo, { zone: event.timezone }).toString() : null,
      visibilityType: bundleForm.visibilityType,
      linkVisibilityToken: bundleForm.linkVisibilityToken,
      userGroupIds: bundleForm.userGroups?.map(g => g.id) || null,
      bundleItemsAttributes: [{
        bundleItemId: getBundleAttributeIdFromExistingBundle(mainProduct.id, mainProductType, bundle),
        itemableType: mainProductType,
        itemableId: mainProduct.id,
        availableQuantity: bundleForm.quantity || null,
        bundleItemPricesAttributes: getBundlePricesToCreateFrom(bundleForm.prices, eventCurrencies),
        discountBundleItemsAttributes: [],
      },
        ...getAddonsToSaveFrom<ProductBundleItemAttributeUpdateModel>(bundleForm, eventCurrencies, bundle),
        ...getSessionsToSaveFrom<ProductBundleItemAttributeUpdateModel>(bundleForm, eventCurrencies, bundle),
      ],
    };
    objectsToSave.push([bundle.id, payload]);
  });
  return objectsToSave;
}

const getBundleAttributeIdFromExistingBundle = (productId: number, productType: ProductBundleItemType, bundle: ProductBundleModel): number | null => {
  switch (productType) {
    case ProductBundleItemType.EVENT_TICKET:
      const ticket = bundle.eventTickets.find(t => t.itemableId === productId);
      return ticket ? ticket.id : null;
    case ProductBundleItemType.AGENDA_SESSION:
      const sessionTicket = bundle.agendaSessionTickets.find(t => t.itemableId === productId);
      return sessionTicket ? sessionTicket.id : null;
    case ProductBundleItemType.EVENT_ADDON:
      const addon = bundle.eventAddons.find(a => a.itemableId === productId);
      return addon ? addon.id : null;
    default:
      return null;
  }
}

const getBundlePricesToCreateFrom = (prices: TicketPriceFormModel[], eventCurrencies: CurrencyModel[]): ItemPricePayloadType[] => {
  const objectsToSave: ItemPricePayloadType[] = [];
  prices.forEach(price => {
    const currency = eventCurrencies.find(c => c.code === price.currencyCode);
    if (!currency) {
      return;
    }
    objectsToSave.push({
      currencyId: currency.id,
      price: price.price,
      taxRate: price.vat,
    });
  });
  return objectsToSave;
}

const getTicketItemCreateModelFrom = (mainForm: ProductFormValueModel): TicketCreateModel => {
  const form = mainForm.mainProduct;
  const translations = form.translations;
  const translationsObject: { [key: string]: TicketTranslatableFields } = {};
  translations.forEach(translation => {
    translationsObject[translation.locale] = {
      name: translation.name,
      description: translation.description,
    }
  });

  return {
    eventCustomFormId: form.customFormId,
    userGroupId: form.assignedGroup?.id || null,
    translations: translationsObject,
    formsDisabled: false,
  }
}

const getTicketItemUpdateModelFrom = (mainForm: ProductFormValueModel): [number, TicketCreateModel] => {
  const form = mainForm.mainProduct;
  const translations = form.translations;
  const translationsObject: { [key: string]: TicketTranslatableFields } = {};
  translations.forEach(translation => {
    translationsObject[translation.locale] = {
      name: translation.name,
      description: translation.description,
    }
  });

  const id = form.id as number;
  const payload: TicketCreateModel = {
    eventCustomFormId: form.customFormId,
    userGroupId: form.assignedGroup?.id || null,
    translations: translationsObject,
    formsDisabled: false,
  }
  
  return [id, payload];
}

export interface ProductPopulateSavedModelsParams {
  itemId: number;
  bundles: ProductBundleModel[];
  form: ProductLocalFormType;
}

const populateSavedModelsToForm = (params: ProductPopulateSavedModelsParams): ProductLocalFormType => {
  const allBundleForms = params.form.controls.bundles as TicketFormBundlesType;
  allBundleForms.controls.forEach(form => {
    const bundleName = form.controls.name.value;
    const createdBundle = params.bundles.find(b => b.name === bundleName);
    if (createdBundle) {
      form.controls.id.setValue(createdBundle.id);
    }
  });
  const mainProduct = params.form.controls.mainProduct as FormGroup<ModelForm<TicketFormModel>>;
  if (params.itemId && params.itemId >= 0) {
    mainProduct.controls.id.setValue(params.itemId);
  }
  return params.form;
}

const createBundleName = (index: number): string => {
  return `bundle-${index}-${Date.now()}`;
}

export interface ProductFromTableToFormParams {
  bundles: ProductBundleModel[];
  tickets: TicketModel[];
  addons: AddonModel[];
  languages: EventLanguageModel[];
  userGroups: TagApiModel[];
  group: ProductOfferGroupModel;
}

const mapTableOfferItemToForm = (params: ProductFromTableToFormParams): ProductLocalFormType => {
  const { bundles, tickets, languages, group, userGroups } = params;
  const form = createProductForm(languages);
  const ticket = tickets.find(t => t.id === group.ticketId);
  if (!ticket) {
    return form;
  }
  const mainProductForm = form.controls.mainProduct as FormGroup<ModelForm<TicketFormModel>>;
  const bundleForms = form.controls.bundles as TicketFormBundlesType;

  const translationForms = mainProductForm.controls.translations as TicketFormTranslationsType;

  // populate main product
  mainProductForm.controls.id.setValue(ticket.id);
  if (ticket.tagUserGroupId) {
    const group = userGroups.find(g => g.id === ticket.tagUserGroupId);
    mainProductForm.controls.assignedGroup.setValue(group || null);
  }

  translationForms.controls.forEach(formGroup => {
    const langCode = formGroup.controls.locale.value;
    const translation = ticket.translations.find(t => t.locale === langCode);
    if (!translation) {
      return;
    }
    formGroup.controls.name.setValue(translation.name);
    formGroup.controls.description.setValue(translation.description);
  });

  // populate bundles

  group.items.forEach(bundleItem => {
    const bundle = bundles.find(b => b.id === bundleItem.bundleId);
    if (!bundle) {
      return;
    }
    const form = createProductBundleForm();
    form.controls.id.setValue(bundle.id);
    form.controls.availableFrom.setValue(bundle.inSaleFrom ? new Date(bundle.inSaleFrom) : null);
    form.controls.availableTo.setValue(bundle.inSaleTo ? new Date(bundle.inSaleTo) : null);
    form.controls.name.setValue(bundle.name);
    form.controls.quantity.setValue(bundleItem.availableQuantity);
    form.controls.visibilityType.setValue(bundle.visibilityType);

    switch (bundle.visibilityType) {
      case ProductVisibility.NO_RESTRICITION:
        form.controls.userGroups.setValue(null);
        form.controls.linkVisibilityToken.setValue(null);
        break;
      case ProductVisibility.USER_GROUPS:
        const groups = bundle.userGroupIds ? userGroups.filter(g => bundle.userGroupIds.includes(g.id)) : [];
        form.controls.userGroups.setValue([...groups]);
        form.controls.linkVisibilityToken.setValue(null);
        break;
      case ProductVisibility.LINK:
        form.controls.linkVisibilityToken.setValue(bundle.linkVisibilityToken);
        form.controls.userGroups.setValue(null);
        break;
    }

    bundleForms.push(form);

    const prices = form.controls.prices as TicketFormPricesType;
    bundleItem.bundleItemPrices.forEach(price => {
      const priceForm = createPriceFormGroup(price.currency.code);
      priceForm.controls.price.setValue(price.price);
      priceForm.controls.vat.setValue(price.taxRate ? Number(price.taxRate) : 0);
      prices.push(priceForm);
    });

    // populate bundle addons
    const formAddons = form.controls.addons as TicketFormAddonsType;
    bundle.eventAddons.forEach(addon => {
      const addonForm = createTicketAddonFormGroup(addon.itemableId);
      addonForm.controls.quantity.setValue(addon.availableQuantity);
      const prices = addonForm.controls.prices as TicketFormPricesType;
      addon.bundleItemPrices.forEach(price => {
        const priceForm = createPriceFormGroup(price.currency.code);
        priceForm.controls.price.setValue(price.price);
        priceForm.controls.vat.setValue(price.taxRate ? Number(price.taxRate) : 0);
        prices.push(priceForm);
      });
      formAddons.push(addonForm);
    });

    // populate bundle session tickets
    const formSessionTickets = form.controls.sessionTickets as TicketFormSessionsType;
    bundle.agendaSessionTickets.forEach(sessionTicket => {
      const sessionTicketForm = createTicketAddonFormGroup(sessionTicket.itemableId);
      sessionTicketForm.controls.quantity.setValue(sessionTicket.availableQuantity);
      const prices = sessionTicketForm.controls.prices as TicketFormPricesType;
      sessionTicket.bundleItemPrices.forEach(price => {
        const priceForm = createPriceFormGroup(price.currency.code);
        priceForm.controls.price.setValue(price.price);
        priceForm.controls.vat.setValue(price.taxRate ? Number(price.taxRate) : 0);
        prices.push(priceForm);
      });
      formSessionTickets.push(sessionTicketForm);
    });
  });

  return form;
}

export const ProductTicketHelper = {
  createTranslationForm: createTicketTranslationFormGroup,
  createItemForm: createTicketFormGroup,
  createPriceForm: createPriceFormGroup,
  createBundleForm: createProductBundleForm,
  createForm: createProductForm,
  createAddonForm: createTicketAddonFormGroup,
  createTicketSessionForm: createTicketSessionFormGroup,
  eventDefaultLanguage: eventDefaultLanguage,
  getSessionsToSaveFrom: getSessionsToSaveFrom,
  getBundleLabelFromForm: getBundleLabelFromForm,
  getBundlesToCreateFrom: getBundlesToCreateFrom,
  getBundlesToUpdateFrom: getBundlesToUpdateFrom,
  getTicketItemCreateModelFrom: getTicketItemCreateModelFrom,
  getTicketItemUpdateModelFrom: getTicketItemUpdateModelFrom,
  createBundleName: createBundleName,
  populateSavedModelsToForm: populateSavedModelsToForm,
  mapTableOfferItemToForm: mapTableOfferItemToForm,
}