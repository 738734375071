<div class="btn-group" [class.disabled-select]="disabled">
  <button type="button" class="btn dropdown-toggle btn-custom" [class.no-transparent]="!transparent" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
    <div class="button-label-span">
      {{ labelToDisplay }}
      <span class="required-star" *ngIf="required">*</span>
    </div>
    <i class="fa fa-heart"></i>
  </button>
  <div class="dropdown-menu" cdkScrollable>

    <div class="form-group search-container" *ngIf="searchActive">
      <img src="/assets/icons/loupe.svg" alt="Search">
      <input [(ngModel)]="search" (ngModelChange)="onSearch()" type="text" class="form-control search" placeholder="{{ 'dropdown_with_search.search' | translate }}">
    </div>

    <hr />

    <ng-container *ngIf="checkboxMode; else singleChoiceDropdown">
      <ng-container>
        <div class="dropdown-item" *ngFor="let option of optionsToDisplay">
          <mat-checkbox (click)="$event.stopPropagation()" (change)="onCheckChange($event, option)" [checked]="isChecked(option)" class="mr-2">
          </mat-checkbox>
          <span class="mt-1">{{ option.name || option.value }}</span>
        </div>
      </ng-container>
    </ng-container>

    <ng-template #singleChoiceDropdown>
      <option class="dropdown-item" *ngIf="!required" [class.active]="selected === null" (click)="resetSelect()">{{ 'dropdown_with_search.not_selected' | translate }}</option>
      <ng-container>
        <div *ngFor="let option of optionsToDisplay; trackBy: trackById" class="dropdown-item" [class.active]="isActive(option)" (click)="select(option)">
          <div class="dropdown-item-content">
            <img *ngIf="option.icon" [src]="option.icon" alt="">
            <div *ngIf="!customTemplate">
              {{ getOptionNameToDisplay(option) }}
            </div>
          </div>

          <div *ngIf="customTemplate">
            <ng-container *ngIf="headerTemplateRef" [ngTemplateOutlet]="headerTemplateRef" [ngTemplateOutletContext]="{$implicit:option}">
            </ng-container>
          </div>
        </div>
      </ng-container>
    </ng-template>

  </div>
</div>